@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@font-face {
  font-family: 'Bebas Neue';
  src: url('bebas.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.bebas {
    font-family: "Bebas Neue", sans-serif;
}

.roboto {
    font-family: "Roboto", sans-serif;
}

.Dropdown-control {
    cursor: pointer;
    font-style: normal!important;
    font-size: 16px!important;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    background-color: transparent!important;
    border: none!important;
    color: #FFFFFFB2!important;
    text-transform: uppercase!important;
    padding-right:25px!important;
    
  }
  .Dropdown-menu {
    font-style: normal;
    font-size: 16px!important;
    max-height: 300px!important;
    
  }
  .Dropdown-arrow {
    border-color: #FFFFFFB2 transparent transparent!important;
    margin-top: 3px;
   
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent #FFFFFFB2!important;
  }
  
  .button{
    display: inline-flex;
    padding: 12px 30px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    background: #00D1FF;
  }
  .button_main{
    cursor: pointer;
    display: inline-flex;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 74px;
    border: 0.5px solid #918F90;
    background: linear-gradient(180deg, #1D1B1C 0%, #000 81.19%, #252120 96.35%);
    box-shadow: 0px 0px 0.25px 1.25px #262524 inset, 3px 5px 2px -4.75px #FFF inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1px 1px 3px 3px #1A1818 inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset;
  }

.spandiscover span {
  font-weight: 500;
}
  